
import './Project.css';
import { useRef, useEffect } from 'react';


function Project({ path }) {


   const videoRef = useRef(null)
   useEffect(() => {
      videoRef.current.play()
   })

   return (
      <div className="project"> 
         <video 
            ref={videoRef}
            className='video'
            loop muted playsInline preload="auto">
            <source src={path} type="video/mp4" />
         </video>
      </div>
   )
 }

 export default Project
