import './App.css';
import { projects } from './data.js';
import Project from './Project.js'
import Marquee from './Marquee.js'

function App() {

  return (
   <div className="App">
      <div id="header"></div>
      <Marquee />
      { projects.map(project => 
         <Project key={project.id} path={project.path} />
      ) }
      <div id="footer"></div>
   </div>
  );
}

export default App;
