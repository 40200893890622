

import './Marquee.css';
import './noselect.css';
import { useState } from 'react';
import { msg } from './data.js'



function Marquee () {

   const [clicked, setClicked] = useState(false)

   function handleClick() {
      setClicked(!clicked)
   }

   const msgWrap = ( <span className="msg">{msg}</span> )

   return (
      <div id="marqueeWrap">
         <div id="marquee" className="noSelect" onClick={handleClick} style={{animationPlayState: clicked ? "paused" : "running"}} >
            <span className="msgs-collection-1" style={{animationPlayState: clicked ? "paused" : "running"}} >
               { msgWrap }
               { msgWrap }
            </span>
            <span>
               { msgWrap }
               { msgWrap }
            </span>
         </div>
      </div>
   )
}

 export default Marquee
