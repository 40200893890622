

// ⬎ 👇 ⬇️ ⤵ ▼ ⤋ ⬇ ✔️ 🧠 👀 👨‍💻 🍷
const msg2 = ( <span>
      hi ✋, ik ben een <b>developer</b> en <b>designer</b>. <span style= {{ textDecoration: 'underline 2px' }}>Hieronder</span> vind je voorbeelden van mijn <b>werk</b> 👀. Bekijk mijn <i>curriculum vitae</i> op <a href="https://www.linkedin.com/in/guido-greijdanus" style={{ textDecoration: 'none', color: 'blue' }} target="_blank" rel="noopener noreferrer"><b>LinkedIn</b></a>. Ik werk vanuit 📍 Rotterdam en 🍷 Porto. <b><i>Meer weten?</i></b> Stuur een bericht naar <a href="mailto:guido@greijdan.us" style={{ textDecoration: 'none', color: "blue" }}><b>guido@greijdan.us</b></a> of bel me op <b>+31 6 43 55 04 14</b>.
   </span> )

const msg = ( <span>
   hi ✋, ik ben Guido, <b>developer</b> en <b>designer</b>. <span style= {{ textDecoration: 'underline 0.1em' }}>Hieronder</span> vind je voorbeelden van mijn <b>werk</b> 👀. Ik ontwerp en ontwikkel <b>websites</b>, <b>apps</b> en <b>platforms</b> voor klanten als <span style= {{ display: 'inlineBlock', fontWeight: '600', border: '0.15em solid black', padding: '0.2em' }}>UHASSELT</span>, <span style= {{ fontWeight: '900' }}>T<span style= {{ color: '#00AEEF' }}>U</span></span><b>Delft</b> en bedrijven in de <b>creatieve sector</b> 💡. Bekijk mijn <i>curriculum vitae</i> op <a href="https://www.linkedin.com/in/guido-greijdanus" style={{ textDecoration: 'none', color: 'blue' }} target="_blank" rel="noopener noreferrer"><b>LinkedIn</b></a>. Ik werk vanuit 📍 Rotterdam en 🍷 Porto. <b><i>Meer weten?</i></b> Stuur een bericht naar <a href="mailto:guido@greijdan.us" style={{ textDecoration: 'none', color: "blue" }}><b>guido@greijdan.us</b></a> of bel me op <b>+31 6 43 55 04 14</b>.
</span> )


const projects = [
   { id: 'tek', text: "tek architecten", path:'./mov/tek.mp4' },
   { id: 'fgh', text: "F,G,H", path:'./mov/FGH.mp4' },
   { id: 'clothes', text: "c.loth.es", path:'./mov/clothes.mp4' },
   { id: 'tudcampus', text: "TU Delft Campusagenda", path:'./mov/TU.mp4' },
   { id: 'rap', text: "RAP", path:'./mov/RAP.mp4' },
   //{ id: 'guggenheim', text: "Guggenheim Programmer", path:'./mov/guggenheim.mp4' }
]


export { msg, projects }
